body {
  margin: 0;
  font-family: "Inter" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Inter";
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  border-radius: 10px;
  height: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d2d2d0;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #bdbdbb;
  border-radius: 10px;
}

.react-datepicker {
  width: 100%;
  margin-bottom: -3px;
  border-radius: 8px !important;
  border: 1px solid #d2d2d0 !important;
}

.react-datepicker__day-names {
  margin-bottom: -20px !important;
}

.react-datepicker__header__dropdown {
  height: 18px !important;
}

.react-datepicker__month {
  padding: 8px 16px;
  margin: 0px !important;
}

.react-datepicker__year-read-view--down-arrow {
  right: -56px !important;
  top: -35px !important;
  border-color: #8e8d8a !important;
  border-width: 2px 2px 0 0 !important;
  height: 7px !important;
  width: 7px !important;
  display: none !important;
}

.react-datepicker__month-read-view--down-arrow {
  left: -56px !important;
  top: -35px !important;
  border-color: #8e8d8a !important;
  border-width: 2px 2px 0 0 !important;
  height: 7px !important;
  width: 7px !important;
  display: none !important;
}

.react-datepicker__month-read-view--selected-month {
  display: none !important;
}

.react-datepicker__year-read-view--selected-year {
  display: none !important;
}

.react-datepicker__month-container {
  width: 100%;
}

.react-datepicker__day,
.react-datepicker__time-name {
  font-size: 14px;
  font-weight: 600;
  color: #333 !important;
  font-family: "Inter";
  padding-top: 6px !important;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  /* width: 32px !important;
  height: 32px !important;
  margin: 6px 11px !important; */
  width: 40px !important;
  height: 40px !important;
  margin: 6px 5px !important;
}

.react-datepicker__header {
  background-color: #f4f6ff !important;
  padding: 16px !important;
  border-bottom: none !important;
  border-top-right-radius: 8px !important;
  border-top-left-radius: 8px !important;
}

.react-datepicker__navigation--previous {
  left: 8px;
}

.react-datepicker__navigation--next {
  right: 8px;
}

.react-datepicker__navigation {
  top: 15px !important;
}

.react-datepicker__navigation-icon::before {
  border-color: #8e8d8a !important;
  border-width: 2px 2px 0 0 !important;
}

.react-datepicker__month-dropdown {
  background-color: #f4f6ff !important;
  width: 26% !important;
  left: 26% !important;
  top: 37px !important;
  border: 1px solid #d2d2d0 !important;
}

.react-datepicker__month-option--selected,
.react-datepicker__month-year-option--selected {
  left: 7px !important;
}

.react-datepicker__year-dropdown {
  background-color: #f4f6ff !important;
  width: 25% !important;
  left: 54% !important;
  top: 37px !important;
  border: 1px solid #d2d2d0 !important;
}

.react-datepicker__day--keyboard-selected {
  border-radius: 50% !important;
  background-color: #ffa737 !important;
  border: 1px solid #ffa737;
}

.react-datepicker__day--range-end {
  background-color: #ffa737 !important;
}

.react-datepicker__day--range-start {
  background-color: #ffa737 !important;
  border-radius: 50% !important;
}

.react-datepicker__day--selecting-range-start {
  background-color: #ffa737 !important;
}

.react-datepicker__day--in-range {
  background-color: rgba(255, 167, 55, 0.2) !important;
  /* border-radius: 0px !important; */
}

.react-datepicker__day:hover {
  border-radius: 50% !important;
  background-color: rgba(255, 167, 55, 0.2) !important;
}

.react-datepicker__day--disabled:hover {
  border-radius: 50% !important;
  background-color: transparent !important;
}

.react-datepicker__day--in-selecting-range {
  background-color: #ffa737 !important;
}

.react-datepicker__day--today {
  border-radius: 50% !important;
  background-color: transparent !important;
  border: 1px solid #ffa737;
}

.react-colorful {
  width: 100% !important;
  height: 240px !important;
}

.react-colorful__saturation {
  border-radius: 8px !important;
  margin-bottom: 24px;
}

.react-colorful__hue {
  height: 40px;
  border-radius: 8px !important;
}

.react-colorful__hue-pointer {
  width: 12px !important;
  height: 32px !important;
  border-radius: 0 !important;
}

.react-colorful__pointer {
  width: 15px !important;
  height: 15px !important;
}

.react-colorful__pointer.react-colorful__hue-pointer {
  width: 7px !important;
  height: 47px !important;
  border: 2px solid #d2d2d0 !important;
  border-radius: 4px !important;
  z-index: 10;
}

.react-colorful__hue-pointer .react-colorful__pointer-fill {
  background: #fff !important;
}

.react-colorful__hue.react-colorful__last-control {
  height: 29px !important;
}

.upload
  .react-datepicker
  .react-datepicker__header
  .react-datepicker__day-names
  .react-datepicker__day-name {
  margin: 6px 16px !important;
}

.upload
  .react-datepicker
  .react-datepicker__month
  .react-datepicker__week
  .react-datepicker__day {
  margin: 6px 16px !important;
}

.react-datepicker__day--disabled {
  color: #696969 !important;
  opacity: 0.5;
}

.subPopIcon svg {
  width: 100% !important;
}

.recTimeStamp .MuiPaper-root {
  margin: 0px !important;
  border: none !important;
}
.react-datepicker__day--range-start {
  background: #ffa737 !important;
}
.react-datepicker__day--range-end {
  background: #ffa737 !important;
}

.react-datepicker__day--outside-month {
  color: #696969 !important;
  opacity: 0.5;
}
#pdf-page-info {
  color: #fff !important;
}
/* .MuiPopover-paper {
  max-width: 179px !important;
  width: 100%;
} */
