.video-preview {
    margin: 0 auto;
    display: block;
    padding-top: 50px;
    width: 100%;
    max-width: 800px;
  }
  
  .ui-video-seek-slider {
    margin-bottom: 16px;
    width: 100%;
  }
  .ui-video-seek-slider .track .main .connect {
    background-color: #ffa737;
    z-index: 3;
    transform-origin: 0 0;
  }
  .ui-video-seek-slider .thumb .handler {
    background-color: #ffa737;
  }
  .VideoSpeedSelect {
    font-size: 14px;
    font-weight: 400;
    line-height: 18.2px;
    color: #696969;
    background-color: #fff;
    border-radius: 8px !important;
    border: 1px solid #d2d2d0 !important;
  }
  .VideoSpeedSelect:focus {
    background-color: #fff8ec;
    border: 1px solid #ffa737 !important;
  }
  .VideoSpeedSelect:focus-within {
    background-color: #fff8ec;
    border: 1px solid #ffa737 !important;
  }
  .react-dropdown-select-item {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 18.2px !important;
    color: #333333 !important;
    border-top: 1px solid #d2d2d0;
    border-bottom: 0px !important;
    padding: 12px 16px;
  }
  .react-dropdown-select-item:hover {
    background-color: #fff8ec !important;
  }
  .react-dropdown-select-item:focus {
    background-color: #fff8ec !important;
  }
  .react-dropdown-select-item-selected {
    font-size: 14px !important;
    font-weight: 600 !important;
    line-height: 18.2px !important;
    color: #333333 !important;
    background-color: #fff8ec !important;
  }
  .react-dropdown-select-dropdown.react-dropdown-select-dropdown-position-top {
    border: 1px solid #d2d2d0 !important;
    border-top: 0px !important;
    border-radius: 8px;
    bottom: 35px !important;
    width: 88px;
  }
  .react-dropdown-select-content {
    font-size: 14px;
    font-weight: 400;
    line-height: 18.2px;
    color: #696969;
  }
  .react-dropdown-select-input {
    margin: 0 !important;
  }
  .OnHoverMiniPlayer:hover .ShowControlsMini {
    display: block !important;
  }
  .miniVideoSeekSlider .ui-video-seek-slider .track .main .connect {
    height: 8px;
  }
  .miniMobileslider .ui-video-seek-slider .track .main .connect {
    height: 4px;
  }
  .miniMobileslider .ui-video-seek-slider .thumb {
    display: none;
  }
  
  .MiniPrevBtn:hover .ShowPreviewContent {
    display: block !important;
  }
  .MiniNextBtn:hover .ShowNextContent {
    display: block !important;
  }
  .miniVideoHeight {
    height: 202px;
    background: #000;
  }
  .MobileVideoHeight {
    /* height: 211px; */
    background: #000;
  }
  .miniVideoMobile {
    height: 69px;
    max-width: 123px;
    width: 100%;
    background: #000;
  }
  .MobileVideoSpeedSelect .react-dropdown-select-dropdown {
    top: 34px !important;
    width: inherit !important;
  }
  