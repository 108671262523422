@font-face {
  font-family: "Inter";
  src: url("./assets/fonts/Inter-Bold.woff2") format("woff2"),
    url("./assets/fonts/Inter-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("./assets/fonts/Inter-Regular.woff2") format("woff2"),
    url("./assets/fonts/Inter-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("./assets/fonts/Inter-SemiBold.woff2") format("woff2"),
    url("./assets/fonts/Inter-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: "PolySans_Neutral";
  src: url("./assets/fonts/PolySans/PolySans-Neutral.woff") format("woff");
  /* font-weight: 600; */
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: "PolySans_Median";
  src: url("./assets/fonts/PolySans/PolySans-Median.woff") format("woff");
  /* font-weight: 600; */
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: "PolySans_Bulky";
  src: url("./assets/fonts/PolySans/PolySans-Bulky.woff") format("woff");
  /* font-weight: 600; */
  font-style: normal;
  font-display: swap;
}
